:root{
    --license-card-height: 0;
    --license-left-height: 0;
}

.nav-license{
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 6px;
}

.nav-license-tab{
    display: grid;
    font-weight: unset;
    background: #FAFAFA;
    color: #888;
    border-bottom: none;
    cursor: pointer;
    text-align: center;
    font-size: 16px !important;
}

.nav-license-tab-active{
    color:#000;
    border-bottom: 2px solid #BBB;
    font-weight: bold !important;
}

.license-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.license-head-left{
    display: flex;
    align-items: center;
    border: 0 solid #EEE;
    border-radius: 6px;
    padding: 6px;
}

.license-head-left-title > *:first-child{
    display: none;
}

.license-head-left-text{
    font-weight: lighter;
}

/* Multi site */

.license-container-multi-site{
    display: grid;
    grid-template-columns: 38% auto;
    gap: 50px;
}

.license-sites-list, .license-container-multi-gateways{
    max-height: 65vh;
    overflow: auto;
}

.license-sites-list-item{
    transition: background 0.2s;
    cursor: pointer
}

.license-sites-list-item:hover{
    background: #eee;
}

.license-sites-list-item:active, .license-sites-list-item-active{
    background: var(--success);
    color: var(--white)
}

/**********/

.license-container-gateways-standard{
    display: grid;
    grid-template-columns: 50% 50%;
}

.license-site{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.license-site-name{
    font-size: 18px;
}

.licenses-in-use{
    display: grid;
    grid-template-columns: 50% 50%;
}

.license-empty-container{
    border-width: 4px;
    border-style: dashed;
    transition: background 0.3s;
    cursor: pointer;
    height: var(--license-card-height);
    min-height: 158px;
}

.license-empty-container:hover{
    background: #F6F6F6;
}

.license-empty-body{
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.license-info{
    display: flex;
    flex-direction: column;
}

.menu-licenses{
    width: 280px
}

.license-table-header-th{
    display: flex;
    justify-content: space-between;
}

.license-table-record td:nth-child(2){
    text-align: center;
    width: 0;
}

.license-list-state-icon{
    font-size: .875rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    padding: 8px;
    border-radius: 50%;
}

.license-card-icon, .license-card-icon-empty{
    transition: all 0.2s;
    cursor: pointer;
    position: absolute;
    top: 5px;
    left: 8px;
}

.license-card-icon-empty{
    top: 5px;
    opacity: 0.5;
}

.license-card-icon:after{
    content: attr(license-state);
    position: absolute;
    font-weight: lighter;
    transition: all 0.2s;
    color: var(--default);
    opacity: 0;
    left: 0;
}

.license-card-icon:hover:after{
    transform: scale(1);
    left: 32px;
    opacity: 1;
}

.license-details{
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    opacity: 0.7;
    transition: all 0.2s
}

.license-details:hover{
    opacity: 1;
}

.license-details-modal{
    min-width: fit-content;
    z-index: 100000;
}

.license-field-status {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
.checkMark {
    position: absolute;
    height: 25px;
    width: 25px;
    background-color: var(--primary);
}

.checkMark-off {
    background-color: var(--lighter);
}

.checkMark:after {
    content: "";
    position: absolute;
    display: none;
}

input:checked ~ .checkMark:after {
    display: block;
}

.checkMark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid #FFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.license-legend{
    position: absolute;
    right: 20px;
    top: 23px;
    background: var(--white);
    padding: 12px;
    border: 1px solid var(--lighter);
    border-radius: 10px;
    padding-bottom: 0;
    z-index: 1000;
    transition: all 0.2s
}

.license-legend > *{
    cursor: pointer;
    transition: all 0.2s;
}

.license-legend > *:first-child > *:last-child:hover{
    color: var(--dark)
}

.license-legend > *:last-child{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    transform: scaleY(0);
    transform-origin: 50% 0%;
    height: 0;
    transition: all 0.2s;
}

.license-legend[show=true] > *:last-child{
    height: 250px;
    transform: scaleY(1);
}

.license-table-body tr td{
    vertical-align: middle;
}

.license-filter-menu{
    width: max-content;
}

#filters-modal{
    display: none;
}

.license-filter-modal{
    max-width: 320px;
    z-index: 100000;
}

#license-filters-container-modal{
    max-width: 300px;
    margin-bottom: 0 !important;
}

#license-filters-container-modal >*{
    display: flex;
    flex-direction: column;
}

#license-filters-container-modal > * > *{
    margin: 10px;
}

#license-filters-container-modal > * > * > *{
    width: 250px;
}

.license-filters-modal-close{
    position: absolute;
    right: 12px;
    top: 2px;
    font-size: 22px;
    color: #aaa;
    transform: scaleY(0.8);
    cursor: pointer;
}

.license-gateway-not-visible-icon{
    cursor: pointer;
}

.license-gateway-not-visible{
    z-index: 100;
    position: absolute;
    transform: scaleX(0);
    white-space: pre-wrap;
    transition: all 0.2s;
    transform-origin: 0% 50%;
    right: 60px;
    max-width: 200px;
}

.license-gateway-not-visible-icon:hover > * + .license-gateway-not-visible{
    transform: scaleX(1);
}

.license-detail-icon{
    transition: all 0.2s;
    cursor: pointer;
}

.license-detail-icon:hover{
    transform: scale(1.2);
    color: var(--gray) !important
}

.license-available-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.license-available-title{
    font-weight: 600;
}

.license-available-controllers, .license-available-remaining-time{
    margin-top: 2px;
    font-size: 12px;
}

.license-available-info{
    cursor: pointer;
    transition: all 0.2;
}

.unlicensed-gateway{
    margin-left: 10px;
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
}

.btn-activate-license{
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100%;
    padding: 1px 10px;
    font-size: 20px;
}

.license-assign-modal{
    z-index: 100000;
}

@media (max-width: 1600px){
    .licenses-in-use{
        grid-template-columns: auto;
    }
}

.license-left-container{
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}

@media (max-width: 1150px){
    #filters-modal{
        display: block;
        margin-bottom: 20px;
    }

    #license-filters-container{
        display: none !important;
    }

    .license-container-gateways-standard{
        grid-template-columns: auto;
    }

    .licenses-in-use{
        grid-template-columns: 50% 50%;
    }

    .license-container-multi-site{
        grid-template-columns: auto;
    }

    .license-sites-list{
        max-height: 260px;
    }

    .license-head{
        align-items: flex-start;
        max-height: 42px;
    }

    .license-head-left{
        margin-top: 5px;
        border-width: 1px;
    }

    .license-head-left-text{
        font-weight: 500;
    }

    .license-head-left-text > *:last-child{
        display: none;
    }

    .license-head-left-title > *:first-child{
        display:contents;
    }

    .license-head-left, .license-left-container{
        z-index: 1000;
        display: grid;
        grid-template-columns: auto;
        background: #FFF;
        transition: all 0.2s;
        gap: 10px;
        transform-origin: 50% 0%;
    }

    .license-head-left[show=false]{
        height: 36px;
    }

    .license-head-left[show=true]{
        height: calc(52px + var(--license-left-height));
    }
    
    .license-left-container[show=false]{
        height: 0px;
        transform: scaleY(0);
    }

    .license-left-container[show=true]{
        height: var(--license-left-height);
        transform: scaleY(1);
    }
}

@media (max-width: 960px){
    .licenses-in-use{
        grid-template-columns: auto;
    }
}

@media (max-width: 767px){
    .licenses-in-use{
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 714px){
    .licenses-in-use{
        grid-template-columns: auto;
    }
}

@media (max-width: 576px) {
    .license-filter-modal{
        position: absolute !important;
        transform: translateX(-50%) !important;
        left: 50%;
        max-width: 90vw;
        text-align: center;
    }
    
    .license-filter-modal-toggle{
        max-width: 55vw;
        margin: 0 !important
    }
}

@media (max-width: 490px) {
    .license-gateway, .license-card, .licenses-in-use{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .license-card, .licenses-in-use{
        padding: 0 !important;
        margin: 0 !important;
        margin-top: 20px !important;
    }

    .license-legend{
        transition: all 0.2s;
        white-space: nowrap;
    }

    .license-legend[show=false]{
        width: 40px;
    }
    
    .license-legend[show=true]{
        width: 160px;
    }

    .license-legend-title > *:first-child{
        white-space: nowrap;
    }

    .license-legend[show=false] .license-legend-title > *:first-child{
        display: none;
    }
}

@media (max-width: 395px) {
    .license-head{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 42px !important;
        gap: 10px;
    }

    .license-head > *:last-child{
       position: absolute;
       right: 10px;
       top: 110px;
    }

    .license-head-left{
        margin-bottom: -10px;
    }
}