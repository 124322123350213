.activation-code-header div:first-child{
    min-width: 420px;
    gap: 20px;
}

.activation-codes-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-activation-code{
    border-radius: 10px !important;
    cursor: pointer;
    transition: all 0.2s;
    margin: 12px;
    
    min-width: 420px;
    max-width: 420px;
    min-height: 240px;
    max-height: 240px;
}

.card-activation-code:hover{
    box-shadow: 0 0 40px 1px #DDD;
}

.card-body-container{
    display: grid;
    grid-template-columns: 50% auto;
}

.trademark-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: auto;
}

.trademark-admin{
    position: relative;
    left: -10px;
    top: -6px;
}

.trademark-logo{
    max-height: 50px;
    margin-bottom: 2px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    width: 75%;
    object-fit: contain;
}

.trademark-info{
    transform: scale(0.8);
}

.food-service, .data-management{
    text-align: center;
    font-family:'Segoe UI', Tahoma, sans-serif;
    font-size:12px;
    color:#333;
    line-height: 8px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.activation-code-info{
    margin-left: 10px;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.activation-code{
    text-align: center;
    margin-top: 10px;
    width: 200%;
}

.activation-code-label{
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.activation-code-guid{
    font-weight: bold;
}

.license-active, .license-sold, .license-expired{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-20deg) scaleX(2.2) scaleY(1.8);
    font-weight: bold;
    font-size: 22px;
    color: #ff00004b;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.license-sold{
    color: #0000003b;
    transform: translate(-50%, -50%) rotate(-20deg) scaleX(2.6) scaleY(1.8);
}

/*----------------------------------------------------*/

.activation-code-details{
    position: absolute;
    width: 400px;
    height: auto;
    max-height: 70vh;
    overflow: auto;
    opacity: 0;
    background-color: #FFFFFFd0;
    backdrop-filter: blur(2px);
    padding: 10px;
    border-radius: 6px;
    transform: scale(0);
    z-index: 3;
    transition: opacity 0.2s ease;
}

.card-body-container .details-license-list-container{
    padding-right: 10px;
    margin-right: -10px;
    max-height: 40vh;
    overflow: auto;
}

.activation-code-details.show{
    position: absolute;
    opacity: 1;
    transform: scale(1)
}

.icon-activation-code-details{
    position: absolute;
    right: 12px;
    top: 22px;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #AAA;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    transition: all 0.2s;
    opacity: 0;
}

.card-body-container:hover .icon-activation-code-details{
    opacity: 1;
}

.card-body-container:hover .icon-activation-code-details:hover{
    color: #0C4A8D !important;
}

.activation-code-sales-note, .activation-code-sold-for{
    padding: 0px !important;
}

.activation-code-sales-note-text-area, .activation-code-sold-for-input{
    border-color: #00000000 !important;
    background-color: #FFF !important;
    margin: 0px !important;
    border-radius: 0;
    transition: all 0.2s;
}

.activation-code-sales-note-text-area{
    min-width: 330px;
    min-height: 290px;
}

.sales-note-text-area-changed, .sold-for-input-changed, .sales-note-text-area-changed:hover, .sold-for-input-changed:hover{
    border-color: var(--danger) !important;
    border-width: 1px !important;
}



/*----------------------------------------------------*/

.modal-license{
    max-height: 40vh;
    overflow: auto;
    overflow-x: hidden;
}

.modal-sales-note-text-area{
    min-height: 80px;
}

.modal-license-list {
    display: grid;
    grid-template-columns: 8fr 0.5fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 10px;
    grid-auto-flow: row;
    grid-template-areas: "modal-license-duration modal-license-buttons" "modal-license-file .";
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    align-items: top;
}

.modal-license-duration {
    grid-area: modal-license-duration;
    display: flex;
    align-items: center;
    gap: 10px;
}

.modal-license-file {
    grid-area: modal-license-file;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.modal-license-buttons {
    grid-area: modal-license-buttons;
}

.modal-license-addon span{
    color: #FFF;
}

#modal-license-item{
    padding: 6px;
}

.modal-license-file-input{
    cursor: pointer;
    background: #FFF !important;
    transition: border-color 0.2s;
}

.modal-license-file-input:active{
    border-color: #CFE7F6;
}

.menu-license{
    max-height: 200px;
    overflow: auto;
}


@media (max-width: 1239px){
    .card-activation-code{
        transform: scale(0.9);
        margin: 0px;
        margin-left: -20px;
    }
}

@media (max-width: 470px){
    .card-activation-code{
        min-width: 370px;
        max-width: 370px;
    }
}
